import React from "react";
import { Helmet } from "react-helmet-async";

type Props = {
  title: string;
};

const SEO: React.FC<Props> = ({ title }) => (
  <Helmet titleTemplate="%s - JP Place" defaultTitle="JP Place">
    <title>{title}</title>
  </Helmet>
);

export default SEO;
