import config from "config";
import * as Sentry from "@sentry/react";

export const captureException = (err: any) => {
  Sentry.setExtra("error", err);
  Sentry.captureException(err, {
    tags: {
      environment: config.developmentMode ? "development" : "production",
    },
  });
};
