import React from "react";
import { useUser, usePostLogin } from "@entities/user/user-queries";
import Button from "components/core/button";
import Card from "components/core/card";
import Input from "components/core/input";
import SEO from "components/seo";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type LoginForm = {
  username: string;
  password: string;
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const userQuery = useUser();
  const { mutateAsync: handleLogin } = usePostLogin();
  const { handleSubmit, register } = useForm<LoginForm>();

  React.useEffect(() => {
    if (userQuery.isSuccess) {
      navigate("/", { replace: true });
    }
  }, [navigate, userQuery.isSuccess]);

  const onLogin = handleSubmit((values: LoginForm) => {
    const { username, password } = values;
    handleLogin({ username, password });
  });

  return (
    <div className="container mx-auto mt-4">
      <SEO title="เข้าสู่ระบบ"></SEO>
      <div className="flex mx-4">
        <div className="w-full mx-auto md:w-1/2 xl:w-1/4">
          <Card>
            <h2 className="text-lg text-center">เข้าสู่ระบบ</h2>
            <form onSubmit={onLogin}>
              <div className="form-group">
                <Input
                  name="username"
                  type="text"
                  label="ชื่อผู้ใช้"
                  isRequired
                  register={register}
                />
              </div>
              <div className="form-group">
                <Input
                  name="password"
                  type="password"
                  label="รหัสผ่าน"
                  isRequired
                  register={register}
                />
              </div>
              <Button type="submit" block>
                เข้าสู่ระบบ
              </Button>
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Login;
