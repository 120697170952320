import cx from "classnames";
import React from "react";

export type CardProps = {
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
};

const Card: React.FC<CardProps> = ({ children, className, onClose }) => {
  return (
    <div
      className={cx("bg-white shadow-md rounded p-4 mb-4 relative", className)}
    >
      {onClose && (
        <div
          role="button"
          aria-hidden
          className="absolute top-0 right-0 w-4 h-4 m-2 text-gray-600 cursor-pointer"
          onClick={onClose}
        >
          &times;
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
