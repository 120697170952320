import { isAddItemWithPicsEnable } from "libs/features";
import React from "react";

const billRoutes = [
  {
    path: "/bills/create-consignment-invoice",
    component: isAddItemWithPicsEnable
      ? React.lazy(() => import("modules/bill/consignment-invoice-create-v2"))
      : React.lazy(() => import("modules/bill/consignment-invoice-create")),
  },
  {
    path: "/bills/consignment-invoice/",
    component: React.lazy(
      () => import("modules/bill/consignment-invoice-list")
    ),
  },
  {
    path: "/bills/consignment-invoice/:billId",
    component: React.lazy(
      () => import("modules/bill/consignment-invoice-detail")
    ),
  },
  {
    path: "/bills/consignment-invoice/:billId/print",
    component: React.lazy(
      () => import("modules/bill/consignment-invoice-detail-print")
    ),
  },
  {
    path: "/bills/create-cash-invoice",
    component: React.lazy(() => import("modules/bill/cash-invoice-create")),
  },
  {
    path: "/bills/cash-invoice/",
    component: React.lazy(() => import("modules/bill/cash-invoice-list")),
  },
  {
    path: "/bills/cash-invoice/:billId",
    component: React.lazy(() => import("modules/bill/cash-invoice-detail")),
  },
  {
    path: "/bills/cash-invoice/:billId/print",
    component: React.lazy(
      () => import("modules/bill/cash-invoice-detail-print")
    ),
  },
];

export default billRoutes;
