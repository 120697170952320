import React from "react";

const settingRoutes = [
  {
    path: "/settings/users",
    component: React.lazy(() => import("modules/setting/users")),
  },
  {
    path: "/settings/stocks",
    component: React.lazy(() => import("modules/setting/stocks")),
  },
];

export default settingRoutes;
