import React from "react";

import Navbar from "./core/navbar";

const Layout: React.FC = ({ children }) => (
  <React.Fragment>
    <Navbar />
    <div>{children}</div>
  </React.Fragment>
);

export default Layout;
