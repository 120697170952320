import React from "react";

const reportRoutes = [
  {
    path: "/reports/",
    component: React.lazy(() => import("modules/reports/report-list")),
  },
  {
    path: "/reports/daily-report/",
    component: React.lazy(() => import("modules/reports/daily-report")),
  },
  {
    path: "/reports/best-seller-consignment-items-report/",
    component: React.lazy(
      () => import("modules/reports/best-seller-consignment-items-report")
    ),
  },
  {
    path: "/reports/best-seller-cash-items-report/",
    component: React.lazy(
      () => import("modules/reports/best-seller-cash-items-report")
    ),
  },
  {
    path: "/reports/stock-card-report/",
    component: React.lazy(() => import("modules/reports/stock-card-report")),
  },
  // {
  //   path: "/reports/stock-summary-report/",
  //   component: React.lazy(() => import("modules/reports/stock-summary-report")),
  // },
  {
    path: "/reports/product-report/",
    component: React.lazy(() => import("modules/reports/product-report")),
  },
  {
    path: "/reports/most-paid-consignment-buyers/",
    component: React.lazy(
      () => import("modules/reports/most-paid-consignment-buyers")
    ),
  },
  {
    path: "/reports/most-paid-cash-buyers/",
    component: React.lazy(
      () => import("modules/reports/most-paid-cash-buyers")
    ),
  },
];

export default reportRoutes;
