import React from "react";

const customerRoutes = [
  {
    path: "/customers/",
    component: React.lazy(() => import("modules/customer/list")),
  },
  {
    path: "/customers/create",
    component: React.lazy(() => import("modules/customer/create")),
  },
  {
    path: "/customers/map",
    component: React.lazy(() => import("modules/customer/map")),
  },
  {
    path: "/customers/:customerId",
    component: React.lazy(() => import("modules/customer/detail")),
  },
  {
    path: "/customers/:customerId/reports/printable-stock-bill",
    component: React.lazy(
      () => import("modules/customer/printable-stock-bill")
    ),
  },
];

export default customerRoutes;
