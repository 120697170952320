import React from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import store from "../libs/localStorage";

export const useLogout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    store.removeToken();
    queryClient.removeQueries("me");
    toast.success("ออกจากระบบสำเร็จ");
    navigate("/login", { replace: true });
  }, [navigate, queryClient]);
};

const useAuth = () => {
  const token = store.getToken().access;
  const handleLogout = useLogout();

  React.useEffect(() => {
    if (!token) {
      handleLogout();
    }
  }, [handleLogout, token]);
};

export default useAuth;
