import { Navigate } from "react-router-dom";

import Login from "modules/auth/login";
import billRoutes from "modules/bill/routes";
import customerRoutes from "modules/customer/routes";
import productRoutes from "modules/product/routes";
import stockRoutes from "modules/stock/routes";
import settingRoutes from "modules/setting/routes";
import reportRoutes from "modules/reports/routes";

const routes = [
  {
    path: "/",
    component: () => <Navigate to="/customers/create" />,
  },
  { path: "/login", component: Login },
  ...customerRoutes,
  ...billRoutes,
  ...productRoutes,
  ...stockRoutes,
  ...settingRoutes,
  ...reportRoutes,
];

export default routes;
