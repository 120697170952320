import { useUser } from "@entities/user/user-queries";

const useAccess = () => {
  const userQuery = useUser();

  return {
    hasRole: (role: "admin" | "officer" | "user") => {
      return userQuery.data?.role === role;
    },
  };
};

export default useAccess;
