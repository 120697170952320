const APP_NAME = "jp-places";

const store = {
  setToken: ({ access, refresh }: Record<string, string>) => {
    if (access) {
      window.localStorage.setItem(`${APP_NAME}-token`, access);
    }
    if (refresh) {
      window.localStorage.setItem(`${APP_NAME}-refresh-token`, refresh);
    }
  },
  getToken: () => ({
    access: window.localStorage.getItem(`${APP_NAME}-token`),
    refresh: window.localStorage.getItem(`${APP_NAME}-refresh-token`),
  }),
  removeToken: () => {
    window.localStorage.removeItem(`${APP_NAME}-token`);
    window.localStorage.removeItem(`${APP_NAME}-refresh-token`);
  },
};

export default store;
