import config from "config";

export const isTradeRouteEnable = config.features.trade_route;

export const isCustomerImages = config.features.customer_images;

export const isDailyReportEnable = config.features.daily_report;

export const isStockSummaryReport = config.features.stock_summary_report;

export const isCustomerStockSummaryReport =
  config.features.customer_stock_summary_report;

export const isAddItemWithPicsEnable = config.features.add_item_with_pics;
