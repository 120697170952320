import cx from "classnames";
import React, { ChangeEvent } from "react";
import { FieldError, RegisterOptions } from "react-hook-form";

export type InputProps = {
  label: string;
  name: string;
  type?: string;
  placeholder?: string;
  error?: FieldError;
  isRequired?: boolean;
  register?: (config: any) => any;
  inline?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  step?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  asTextArea?: boolean;
  className?: string;
};

const Input: React.FC<InputProps> = ({
  label,
  type = "text",
  name,
  error,
  register,
  isRequired,
  placeholder,
  inline,
  asTextArea,
  disabled,
  className,
  ...props
}) => {
  let config: RegisterOptions = {};

  if (isRequired) config = { ...config, required: "กรุณากรอกช่องนี้" };

  const CustomTag = `${asTextArea ? "textarea" : "input"}` as "input";

  return (
    <div
      className={cx({
        "mb-3": !inline,
      })}
    >
      {label && (
        <label className="block mb-2 text-sm text-gray-700" htmlFor={name}>
          {label}
          {isRequired && <span className={"text-red-700"}>*</span>}
          {`:`}
        </label>
      )}
      <CustomTag
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        className={cx(
          "appearance-none border border-gray-300 rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
          {
            "border-red-500 mb-3 leading-tight focus:outline-none focus:shadow-outline":
              error,
            "py-2 px-3": !inline,
            "py-1 px-2": inline,
            "opacity-50 cursor-not-allowed": disabled,
          },
          className
        )}
        disabled={disabled}
        {...(register && { ref: register(config) })}
        {...props}
      />
      {error && <p className="text-xs italic text-red-500">{error.message}</p>}
    </div>
  );
};

export default Input;
