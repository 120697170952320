import React from "react";

const stockRoutes = [
  {
    path: "/stocks/adjust/",
    component: React.lazy(() => import("modules/stock/adjust-bill-list")),
  },
  {
    path: "/stocks/adjust/create",
    component: React.lazy(() => import("modules/stock/adjust-bill-create")),
  },
  {
    path: "/stocks/adjust/:billId",
    component: React.lazy(() => import("modules/stock/adjust-bill-detail")),
  },
  {
    path: "/stocks/import/",
    component: React.lazy(() => import("modules/stock/import-bill-list")),
  },
  {
    path: "/stocks/import/create",
    component: React.lazy(() => import("modules/stock/import-bill-create")),
  },
  {
    path: "/stocks/import/:billId",
    component: React.lazy(() => import("modules/stock/import-bill-detail")),
  },
  {
    path: "/stocks/transfer/",
    component: React.lazy(() => import("modules/stock/transfer-bill-list")),
  },
  {
    path: "/stocks/transfer/create",
    component: React.lazy(() => import("modules/stock/transfer-bill-create")),
  },
  {
    path: "/stocks/transfer/:billId",
    component: React.lazy(() => import("modules/stock/transfer-bill-detail")),
  },
  {
    path: "/stocks/",
    component: React.lazy(() => import("modules/stock/stock-list")),
  },
  {
    path: "/stocks/:stockId",
    component: React.lazy(() => import("modules/stock/stock-detail")),
  },
];

export default stockRoutes;
