import React from "react";

const productRoutes = [
  {
    path: "/products/",
    component: React.lazy(() => import("modules/product/list")),
  },
  {
    path: "/products/categories",
    component: React.lazy(() => import("modules/product/category")),
  },
  {
    path: "/products/brands",
    component: React.lazy(() => import("modules/product/brand")),
  },
  {
    path: "/products/dealers",
    component: React.lazy(() => import("modules/product/dealer")),
  },
  {
    path: "/products/presets",
    component: React.lazy(() => import("modules/product/preset")),
  },
  {
    path: "/products/presets/:presetId",
    component: React.lazy(() => import("modules/product/preset-detail")),
  },
];

export default productRoutes;
