import devConfig from "./config.development";
import prodConfig from "./config.production";

let config = devConfig;

if (import.meta.env.PROD) {
  config = prodConfig;
}

export default config;
