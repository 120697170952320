import React from "react";
import Modal from "./modal";

const ModalContext = React.createContext<any>({});
const { Provider } = ModalContext;

const ModalProvider: React.FC = ({ children }) => {
  const [modal, setModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(
    "I'm the Modal Content"
  );

  const handleModal = (modal: boolean, content: any) => {
    setModal(modal);
    if (content) {
      setModalContent(content);
    }
  };

  return (
    <Provider value={{ modal, handleModal, modalContent }}>
      <Modal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
