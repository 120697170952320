import React, { useRef } from "react";
import ReactDOM from "react-dom";

import { ModalContext } from "./modal-context";

const Modal = () => {
  const { modalContent, handleModal, modal } = React.useContext(ModalContext);
  const ref = useRef<any>(null);

  const modalEl = document.querySelector("#modal-root");

  React.useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleModal(false, "");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleModal, ref]);

  if (modal && modalEl) {
    return ReactDOM.createPortal(
      <div className="fixed inset-0 overflow-y-auto" style={{ zIndex: 999999 }}>
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-600 opacity-50"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <div
            className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            ref={ref}
          >
            {modalContent}
          </div>
        </div>
      </div>,
      modalEl
    );
  } else return null;
};

export default Modal;
