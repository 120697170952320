import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faFileInvoice,
  faFileInvoiceDollar,
  faLocationArrow,
  faMapMarked,
  faMapSigns,
  faMinusCircle,
  faPlus,
  faPrint,
  faSearch,
  faShoppingBag,
  faStore,
  faTruck,
  faUsers,
  faTimes,
  faBars,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faFileInvoice,
  faFileInvoiceDollar,
  faLocationArrow,
  faMapMarked,
  faMapSigns,
  faMinusCircle,
  faPlus,
  faPrint,
  faSearch,
  faShoppingBag,
  faStore,
  faTruck,
  faUsers,
  faTimes,
  faBars
);
