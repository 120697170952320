import cx from "classnames";
import React from "react";

const colorScheme = {
  default: {
    bg: "bg-gray-600",
    bgHover: "hover:bg-gray-600",
    border: "border-gray-600",
    borderHover: "hover:border-gray-600",
    text: "text-white",
  },
  defaultOutline: {
    bg: "bg-white",
    bgHover: "hover:bg-gray-600",
    border: "border-gray-600",
    borderHover: "hover:border-gray-600",
    text: "text-gray-600",
    textHover: "hover:text-white",
  },
  danger: {
    bg: "bg-red-600",
    bgHover: "hover:bg-red-600",
    border: "border-red-600",
    borderHover: "hover:border-red-600",
    text: "text-white",
  },
  dangerOutline: {
    bg: "bg-white",
    bgHover: "hover:bg-red-600",
    border: "border-red-600",
    borderHover: "hover:border-red-600",
    text: "text-red-600",
    textHover: "hover:text-white",
  },
  infoOutline: {
    bg: "bg-white",
    bgHover: "hover:bg-blue-600",
    border: "border-blue-600",
    borderHover: "hover:border-blue-600",
    text: "text-blue-600",
    textHover: "hover:text-white",
  },
  info: {
    bg: "bg-blue-600",
    bgHover: "hover:bg-blue-600",
    border: "border-blue-600",
    borderHover: "hover:border-blue-600",
    text: "text-white",
  },
  warn: {
    bg: "bg-yellow-600",
    bgHover: "hover:bg-yellow-600",
    border: "border-yellow-600",
    borderHover: "hover:border-yellow-600",
    text: "text-white",
  },
  success: {
    bg: "bg-green-600",
    bgHover: "hover:bg-green-600",
    border: "border-green-600",
    borderHover: "hover:border-green-600",
    text: "text-white",
  },
  successOutline: {
    bg: "bg-white",
    bgHover: "hover:bg-green-600",
    border: "border-green-600",
    borderHover: "hover:border-green-600",
    text: "text-green-600",
    textHover: "hover:text-white",
  },
};

export type ButtonProps = {
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: any;
  className?: string;
  block?: boolean;
  children: React.ReactNode;
  size?: "small" | "normal";
  color?: keyof typeof colorScheme;
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  className,
  block,
  type = "button",
  size = "normal",
  color = "default",
  disabled,
}) => {
  const computeColorClassname = (key: keyof typeof colorScheme) =>
    Object.values(colorScheme[key]).join(" ");

  return (
    <button
      className={cx(
        className,
        computeColorClassname(color),
        {
          "w-full text-center": block,
          "py-1 px-2 text-sm": size === "small",
          "py-2 px-4": size === "normal",
          "opacity-50 cursor-not-allowed": disabled,
          "cursor-pointer": !disabled,
        },
        "rounded inline-block border select-none"
      )}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
